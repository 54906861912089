import { gql } from '../__generated__/gql';

export const UPDATE_PROJECT = gql(/* GraphQL */ `
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      id
      title
      type
      published
      redirectUrl
      questions {
        id
        type
        text
        required
        aiInsights
        aiConvoDepth
        multipleSelect
        randomize
        options {
          id
          label
          value
        }
        tags {
          id
          name
          color
        }
      }
      tags {
        id
        name
        color
      }
    }
  }
`);
